import React from 'react'
import logo from '../../assests/images/logo2.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
const Footer = () => {
  return (
    <div className='footer'>  
     <div className="container">

    <div className="footer-top">

      {/* <div className="footer-brand" >
        <a href="/" className="logo" >
          <img src={logo} alt="Ridex logo" style={{width:100}}/>
        </a>
      </div> */}

  

      {/* <ul className="footer-list">

        <li>
          <p className="footer-list-title">Neighborhoods in New York</p>
        </li>

        <li>
          <a href="/" className="footer-link">Manhattan</a>
        </li>

        <li>
          <a href="/" className="footer-link">Central New York City</a>
        </li>

        <li>
          <a href="/" className="footer-link">Upper East Side</a>
        </li>

        <li>
          <a href="/" className="footer-link">Queens</a>
        </li>

        <li>
          <a href="/" className="footer-link">Theater District</a>
        </li>

        <li>
          <a href="/" className="footer-link">Midtown</a>
        </li>

        <li>
          <a href="/" className="footer-link">SoHo</a>
        </li>

        <li>
          <a href="/" className="footer-link">Chelsea</a>
        </li>

      </ul> */}

    </div>

    <div className="footer-bottom" style={{backgroundColor:'yellow'}}>

      <ul className="social-list">

        <li>
          <a href="/" className="social-link">
            <FacebookIcon/>
          </a>
        </li>

        <li>
          <a href="https://www.instagram.com/touzabilarab/" className="social-link">
            <InstagramIcon/>
          </a>
        </li>
        <li>
          <a href="/" className="social-link">
          <EmailIcon/>
          </a>
        </li>

      </ul>

      {/* <p className="copyright" >
        &copy; 2023 . Alla rättigheter förbehållna

      </p> */}

    </div>

  </div></div>
  )
}

export default Footer