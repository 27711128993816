import React from "react";
import NavBar from "../Components/NavBar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import car from "../assests/images/about.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import img from "../assests/images/touzabillar2.jpeg";
import img2 from "../assests/images/about1.jpeg";
const AnyReactComponent = ({ text }) => (
  <div>
    <RoomIcon style={{ fontSize: "2rem", color: "red" }} />
  </div>
);
const AboutScreen = () => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const classes = "jj";
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Set the desired interval in milliseconds
    arrows: false, // Set the arrows option to false to hide the arrows
  };
  const defaultProps = {
    center: {
      lat: 59.205528,
      lng: 17.643089,
    },
    zoom: 11,
  };
  return (
    <div className="section">
      <NavBar />

      <div className="section">
        <div className="container">
          <Grid container className="container-box">
            <Grid item xs={12} md={6} sm={12}>
              {/* <div style={{padding:20, position:'absolute', top:'25%'}}> */}
              <div>
                <Slider {...settings}>
                  <img src={car} alt="not show" width="100%" height="100%" />
                  <img
                    src={img}
                    alt="not show"
                    width="100%"
                    height="10%"
                    className="imgg"
                  />
                  <img src={img2} alt="not show" width="100%" height="100%" />
                </Slider>
              </div>

              {/* </div> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 8,
              }}
              className="login-box-container"
            >
              <Grid
                className={classes.size}
                item
                // xs={10}
                // sm={6}
                // md={10}
                elevation={1}
                square
                style={{ width: "100%", padding: 40 }}
              >
                {/* style={windowWidth <= 464 ?{ padding:20, backgroundColor:'red'}:{}}  */}
                <div
                  className="login-box"
                  style={
                    windowWidth <= 464
                      ? { padding: 10, textAlign: "center" }
                      : {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: 12,
                          justifyContent: "center",
                          textAlign: "center",
                        }
                  }
                >
                  {windowWidth <= 464 ? <></> : <></>}
                  <Typography component="h1" variant="h4" sx={{ mt: 1 }}>
                    OM OSS
                  </Typography>

                  <Typography style={{ textAlign: "center", color: "white" }}>
                    {/* {erorr} */}
                    Välkommen till vår passionerade familjeägda företag vi delar
                    kärleken till bilar. Med över ett decennium av dedikerat
                    arbete och erfarenhet i bilbranschen, förblir vi engagerade
                    i att erbjuda dig det absolut bästa.{" "}
                  </Typography>
                  <Typography style={{ textAlign: "center", color: "white" }}>
                    För oss handlar det inte bara om att sälja bilar. Vi strävar
                    efter att skapa en personlig koppling mellan dina drömmar
                    och våra tjänster. Vi tar hänsyn till dina specifika behov
                    och önskemål, och matchar dem med det perfekta fordonet
                    eller den rätta tjänsten, formad bara för dig.
                  </Typography>
                  <Typography style={{ textAlign: "center", color: "white" }}>
                    Och om begagnade bilar är vad du söker, lita på oss för att
                    ge dig bara det bästa. Våra bilar är inte bara 'begagnade';
                    de är noga utvalda, testade och besiktade, alltid i utmärkt
                    skick och väl servade. Vi tror på att föra in liv och
                    kvalitet i varje bil vi levererar, för att försäkra att din
                    körupplevelse blir enastående.
                  </Typography>
                  <Typography style={{ textAlign: "center", color: "white" }}>
                    Så kom och bli en del av vår familj, och låt oss ge dig den
                    kvalitet och service du förtjänar.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="MapBox">
            <div className="item">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCESjWTFkQXJZlWFwTPwyqM7_-xCcITYm0",
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={59.205528}
                  lng={17.643089}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>
            <div className="address">
              <p className="addbox">
                <span className="boldtext">Våra Öppettider:</span>
                Måndag – Fredag 09:30– 17:30 <br />
                Lördag 11:00 – 15:00 <br />
              </p>
              <p style={{ textAlign: "center" }}>
                <span className="boldtext">Vår Adress</span>
                Wedavägen 4C, 152 42 Södertälje
                <br />
                Tel: 08-550 66 158
              </p>
            </div>
          </div>
        </div>

        <div></div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutScreen;
