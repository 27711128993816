import React from 'react'
import NavBar from '../Components/NavBar/NavBar';
import HeroCompo from '../Components/Hero/HeroCompo';
import InfoCard from '../Components/infocard/InfoCard';
import Footer from '../Components/Footer/Footer';
import VideoCompo from '../Components/VideoCompo/VideoCompo';
// import Carslide from '../Components/carslide/Carslide';
const Homescreen = () => {
  return (
    <div>    <NavBar/>
    <HeroCompo/>
    {/* <FeutherdCarCompo/> */}
     <VideoCompo/>
     <InfoCard/>
     {/* <Carslide/> */}
    {/* <BlogCompo/> */}
    <Footer/></div>
  )
}

export default Homescreen