import React from 'react'
import NavBar from '../Components/NavBar/NavBar'
import Footer from '../Components/Footer/Footer'
import BlogCardCompo from '../Components/BlogCard/BlogCardCompo';

const BlogScreen = () => {
  return (
    <div>
      <NavBar/>
        <BlogCardCompo/>
        <Footer/>
    </div>
  )
}

export default BlogScreen