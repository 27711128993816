import React from 'react'
import car from '../../assests/images/car-1.jpg'

import { useNavigate } from 'react-router'
const Carcard = () => {
  const navigate = useNavigate()
  return (
    <li>
    <div className="featured-car-card">

      <figure className="card-banner">
        <img src={car} alt="Toyota RAV4 2021" loading="lazy" width="440" height="300"
          className="w-100"/>
      </figure>

      <div className="card-content">

        <div className="card-title-wrapper">
          <h3 className="h3 card-title">
            <a href="/">Toyota RAV4</a>
          </h3>

          <data className="year" value="2021">2021</data>
        </div>

        <ul className="card-list">

          <li className="card-list-item">

            <span className="card-item-text">4 människor
</span>
          </li>

          <li className="card-list-item">

            <span className="card-item-text">Hybrid</span>
          </li>

          <li className="card-list-item">

            <span className="card-item-text">6.1km / 1-litre</span>
          </li>

          <li className="card-list-item">

            <span className="card-item-text">Automatisk</span>
          </li>

        </ul>

        <div className="card-price-wrapper">

          <p className="card-price">
            <strong>$440</strong> 
          </p>

          <button className="btn fav-btn" aria-label="Add to favourite list">
          </button>

          <button className="btn" onClick={()=>{navigate('/cardetail')}}>Visa detalj
</button>

        </div>

      </div>

    </div>
  </li>
  )
}

export default Carcard