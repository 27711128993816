import React from "react";
import logo from "../../assests/images/logo2.png";

const NavBar = () => {
  const navToggleFunc = function () {
    const overlay = document.querySelector("[data-overlay]");
    const navbar = document.querySelector("[data-navbar]");
    const navToggleBtn = document.querySelector("[data-nav-toggle-btn]");
    console.log(navToggleBtn, "nbtn");
    console.log(navbar, "navbar");
    navToggleBtn.classList.toggle("active");
    navbar.classList.toggle("active");
    overlay.classList.toggle("active");
  };
  return (
    <div className="header">
      <div className="container">
        <div className="overlay" data-overlay></div>

        <a href="/" className="logo">
          <img src={logo} alt="Ridex logo" className="logoimg" />
        </a>

        <nav className="navbar" data-navbar>
          <ul className="navbar-list">
            <li>
              <a href="/" className="navbar-link" data-nav-link>
                HEM
              </a>
            </li>

            <li>
              <a
                href="https://www.blocket.se/butik/touza-bilar"
                className="navbar-link"
                data-nav-link
              >
                BIL I LAGER
              </a>
            </li>

            <li>
              <a href="/about" className="navbar-link" data-nav-link>
                OM OSS
              </a>
            </li>
            <li className="sbtn">
              <a
                href="https://www.blocket.se/butik/touza-bilar"
                aria-labelledby="aria-label-txt"
              >
                <ion-icon name="car-outline"></ion-icon>

                <span id="aria-label-txt">UTFORSKA BILAR</span>
              </a>
            </li>

            {/* <li>
      <a href="/blog" className="navbar-link" data-nav-link>Blogg</a>
    </li> */}
          </ul>
        </nav>

        <div className="header-actions">
          <div className="header-contact">
            <a href="#" style={{ color: "white" }}>
              Måndag – Fredag 09:30– 17:30
            </a>

            <span style={{ color: "white" }}>Lördag 11:00 – 15:00</span>
          </div>

          <a
            href="https://www.blocket.se/butik/touza-bilar"
            className="btn"
            aria-labelledby="aria-label-txt"
          >
            <ion-icon name="car-outline"></ion-icon>

            <span id="aria-label-txt">UTFORSKA BILAR</span>
          </a>

          <button
            className="nav-toggle-btn"
            data-nav-toggle-btn
            aria-label="Toggle Menu"
            onClick={navToggleFunc}
          >
            <span className="one"></span>
            <span className="two"></span>
            <span className="three"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
