import React, {useState} from 'react'
import NavBar from '../Components/NavBar/NavBar'
import Footer from '../Components/Footer/Footer'
import { Button, Grid } from '@mui/material'
import car from '../assests/images/car-1.jpg'
import car1 from '../assests/images/car-2.jpg'
import car2 from '../assests/images/car-3.jpg'
import car3 from '../assests/images/car-4.jpg'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import SpeedIcon from '@mui/icons-material/Speed';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
const CarDetailScreen = () => {
  const [carmain, setcarmain] = useState(car)
  return (
    <div>
<NavBar/>
<div className='container' style={{marginTop:100}}>
<p style={{marginBottom:10}}>Bil I Lager
 {'>'} bil</p>

<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={12} md={8} sm={12} >
    <div >
        <img src={carmain} alt="car" width='100%'/>
        <div className='imgbox'>
        <img src={car} className='img' alt="car" onClick={()=>{ setcarmain(car)}}/>
        <img src={car1} className='img' alt="car" onClick={()=>{ setcarmain(car1)}}/>
        <img src={car2} className='img' alt="car" onClick={()=>{ setcarmain(car2)}}/>
        <img src={car3} className='img' alt="car" onClick={()=>{ setcarmain(car3)}}/>

        </div>
  <div>
    <h1 className='tital'>Bilöversikt
</h1>
    <div className='car-overview-box'>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
    <Grid item xs={4}>
     <div>
      <p style={{color:'grey'}}>Gör år
</p>
      <p>Julay 2019</p>
     </div>
      </Grid>
      <Grid item xs={4}>
      <div>
      <p style={{color:'grey'}}>Registreringsår</p>
      <p>Julay 2020</p>
     </div>
      </Grid>
      <Grid item xs={4}>
      <div>
      <p style={{color:'grey'}}>Bränsletyp</p>
      <p>Diesel</p>
     </div>
      </Grid>

</Grid>

    </div>
    <div className='car-overview-box'>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
    <Grid item xs={4}>
     <div>
      <p style={{color:'grey'}}>KM Driven</p>
      <p>64,832 Kms</p>
     </div>
      </Grid>
      <Grid item xs={4}>
      <div>
      <p style={{color:'grey'}}>Överföring</p>
      <p>Manual</p>
     </div>
      </Grid>
      <Grid item xs={4}>
      <div>
      <p style={{color:'grey'}}>Antal ägare
</p>
      <p>1st Owner</p>
     </div>
      </Grid>

</Grid>


    </div>
    <div className='car-overview-box'>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
    <Grid item xs={4}>
     <div>
      <p style={{color:'grey'}}>Försäkringens giltighet
</p>
      <p>May 2024</p>
     </div>
      </Grid>
      <Grid item xs={4}>
      <div>
      <p style={{color:'grey'}}>Bilens läge
</p>
      <p>Stocklume, Sweden</p>
     </div>
      </Grid>


</Grid>


    </div>
  </div>
    </div>
    </Grid>
    <Grid item xs={12} md={4} sm={12}>
  <div className='detailbox'>
    <div className='box-container'>
    <h3>2019 Toyota Innova Crysta 2.4 V 8 STR</h3>
<span className='footer-link'>64.8K.M  ·  Diesel  ·  Manual</span>
<p><LocationOnIcon/> Stockholm,Swden</p>
<div>
  <span>24553 S.E.K.</span>
  <p style={{fontSize:10}}>fixed on road side</p>
  <Button className='btn'>Show Phone Number</Button>
  
</div>

    </div>
    <div>
  <p style={{fontSize:20, fontWeight:'bold', paddingTop:20}}>Car Specification</p>
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{marginTop:10}}>
  <Grid item xs={6}>
   <div className='box'>
    <div>
    <SpeedIcon style={{fontSize:40}}/>
    </div>
    <div>
      <p>Miltal(ARAI)</p>
      <p>13.68 Kmpl</p>
    </div>
   </div>
  </Grid>
  <Grid item xs={6}>
   <div className='box'>
    <div>
    <LocalGasStationIcon style={{fontSize:40}}/>
    </div>
    <div>
      <p>Bränslekapacitet</p>
      <p>55 ltr</p>
    </div>
   </div>
  </Grid>
  </Grid>
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{marginTop:10}}>
  <Grid item xs={6}>
   <div className='box'>
    <div>
    <AirlineSeatReclineNormalIcon style={{fontSize:40}}/>
    </div>
    <div>
      <p>Säteskapacitet</p>
      <p>8 Unit</p>
    </div>
   </div>
  </Grid>
  <Grid item xs={6}>
   <div className='box'>
    <div>
    <ModeStandbyIcon style={{fontSize:40}}/>
    </div>
    <div>
      <p>Maximal kraft
</p>
      <p>147.57bhp</p>
    </div>
   </div>
  </Grid>
  </Grid>
</div>
  </div>
</Grid>
</Grid>
</div>
<Footer/>
    </div>
  )
}

export default CarDetailScreen