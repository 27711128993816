import React from 'react'
import NavBar from '../Components/NavBar/NavBar'
import Footer from '../Components/Footer/Footer'
import Blog from '../assests/images/blog-1.jpg'
const BlogDetailScreen = () => {
  return (
    <div>
        <NavBar/>
        <div className="container" style={{marginTop:'100px'}}>
        <h1>Öppnande av nya kontor i företaget</h1>
        <img src={Blog} width='100%' height='100%' alt='img'/>
        <br/>
        <div>
            <p>Vi är glada över att dela några spännande nyheter med er alla. Efter månader av noggrann planering och förberedelser är vi glada över att kunna meddela att våra nya kontor öppnas. Denna expansion är en viktig milstolpe för vårt företag och ett bevis på vår fortsatta tillväxt och framgång.
             
</p>
<p>
De nya kontoren är strategiskt belägna [ange platsinformation], och erbjuder en utmärkt affärsmiljö som passar perfekt med vår vision och mål. Den moderna designen, toppmoderna faciliteterna och den senaste tekniken som ingår i det nya utrymmet kommer att ge en förbättrad arbetsmiljö för alla våra anställda.
</p>
<br/>
<h3>Här är några viktiga detaljer om öppnandet av våra nya kontor:


</h3>
<br/>
<p>
1. Datum och tid: Den officiella invigningsceremonin kommer att äga rum den [datum] vid [tid]. Vi uppmuntrar alla anställda att markera detta datum i sina kalendrar eftersom det kommer att bli ett betydelsefullt tillfälle för hela företaget.


</p>
<br/>
<p>
    2.Adress och vägbeskrivning: De nya kontoren finns på [adress]. Bifogat till detta tillkännagivande hittar du en karta med detaljerade anvisningar för din bekvämlighet. Vi rekommenderar att du bekantar dig med platsen innan öppningen.

</p>
<br/>
<p>
    3.Kontorfunktioner: De nya kontoren har designats med din komfort och produktivitet i åtanke. Utrymmet inkluderar moderna arbetsstationer, samarbetsutrymmen, mötesrum utrustade med den senaste audiovisuella tekniken och dedikerade breakout-zoner för avkoppling och föryngring.

</p>
        </div>
        </div>
        <Footer/>
    </div>
  )
}

export default BlogDetailScreen