import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homescreen from "./screen/Homescreen";
import Carscree from "./screen/Carscree";
import CarDetailScreen from "./screen/CarDetailScreen";
// import LoginScreeen from './screen/Auth/LoginScreeen';
// import RegistrationScreen from './screen/Auth/RegistrationScreen';
import BlogScreen from "./screen/BlogScreen";
import AboutScreen from "./screen/AboutScreen";
import BlogDetailScreen from "./screen/BlogDetailScreen";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homescreen />} />
          <Route path="/cars" element={<Carscree />} />
          <Route path="/cardetail" element={<CarDetailScreen />} />
          {/* <Route path="/login" element={<LoginScreeen/>} />
        <Route path="/register" element={<RegistrationScreen/>} /> */}
          <Route path="/blog" element={<BlogScreen />} />
          <Route path="/About" element={<AboutScreen />} />
          <Route path="/blogview" element={<BlogDetailScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
