import React from 'react'
import img from '../../assests/images/hero-banner.jpg'
import img1 from '../../assests/images/car1.jpg'
import img2 from '../../assests/images/car2.jpg'
import img3 from '../../assests/images/car5.jpg'
import img4 from '../../assests/images/car4.jpg'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
const HeroCompo = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Set the desired interval in milliseconds
    arrows: false, // Set the arrows option to false to hide the arrows

  };
  return (
    <div className="section " >
     <div className="hero ">
            <div className="container1 ">
                <div className="hero-content">
                    <p className="h1 hero-text">
                    VÄLKOMMEN TILL TOUZABILAR - DIN PÅLITLIGA PARTNER PÅ RESAN TILL DIN PERFEKTA BIL                    </p>
                    <a href="https://www.blocket.se/butik/touza-bilar" className="btns" >bilar i Lager</a>

                </div>
                

                <div className="hero-banner" style={{ height: 600 }}>
                    <Slider {...settings} >
                        <img src={img}  alt='img err' />
                        <img src={img1} alt='img err' />
                        <img src={img3} alt='img err' />
                        <img src={img4} alt='img err' />
                        <img src={img2} alt='img err' />
                    </Slider>
                </div>
                </div>
                </div>

    </div>
  )
}

export default HeroCompo