import React from 'react'
import blogimg from '../../assests/images/blog-1.jpg'
import blogimg1 from '../../assests/images/blog-2.jpg'
import blogimg2 from '../../assests/images/blog-3.jpg'
import blogimg3 from '../../assests/images/blog-4.jpg'
import blogimg4 from '../../assests/images/blog-5.jpg'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
const BlogCardCompo = () => {
  return (
    <div style={{marginTop:'100px'}}>
         <div className="container">
        <ul className="featured-car-list">

<li>
  <div className="blog-card">

    <figure className="card-banner">

      <a href="/blogview">
        <img src={blogimg} alt="Opening of new offices of the company" loading="lazy"
          className="w-100"/>
      </a>

      <a href="/blogview" className="btn card-badge">Företag</a>

    </figure>

    <div className="card-content">

      <h3 className="h3 card-title">
        <a href="/blogview">Öppnande av nya kontor i företaget
</a>
      </h3>

      <div className="card-meta">

        <div className="publish-date">
          <AccessTimeIcon/>

          <time datetime="2022-01-14">January 14, 2022</time>
        </div>

        <div className="comments">
<ChatBubbleOutlineIcon/>

          <data value="114">114</data>
        </div>

      </div>

    </div>

  </div>
</li>

<li>
  <div className="blog-card">

    <figure className="card-banner">

      <a href="/blogview">
        <img src={blogimg1} alt="What cars are most vulnerable" loading="lazy"
          className="w-100"/>
      </a>

      <a href="/blogview" className="btn card-badge">Reparera</a>

    </figure>

    <div className="card-content">

      <h3 className="h3 card-title">
        <a href="/blogview">Vilka bilar är mest sårbara
</a>
      </h3>

      <div className="card-meta">

        <div className="publish-date">
        <AccessTimeIcon/>

          <time datetime="2022-01-14">January 14, 2022</time>
        </div>

        <div className="comments">
        <ChatBubbleOutlineIcon/>

          <data value="114">114</data>
        </div>

      </div>

    </div>

  </div>
</li>

<li>
  <div className="blog-card">

    <figure className="card-banner">

      <a href="/blogview">
        <img src={blogimg2} alt="Statistics showed which average age" loading="lazy"
          className="w-100"/>
      </a>

      <a href="/blogview" className="btn card-badge">Bilar</a>

    </figure>

    <div className="card-content">

      <h3 className="h3 card-title">
        <a href="/blogview">Statistiken visade vilken medelålder
</a>
      </h3>

      <div className="card-meta">

        <div className="publish-date">
        <AccessTimeIcon/>

          <time datetime="2022-01-14">January 14, 2022</time>
        </div>

        <div className="comments">
        <ChatBubbleOutlineIcon/>

          <data value="114">114</data>
        </div>

      </div>

    </div>

  </div>
</li>

<li>
  <div className="blog-card">

    <figure className="card-banner">

      <a href="/blogview">
        <img src={blogimg3} alt="What´s required when renting a car?" loading="lazy"
          className="w-100"/>
      </a>

      <a href="/blogview" className="btn card-badge">Bilar</a>

    </figure>

    <div className="card-content">

      <h3 className="h3 card-title">
        <a href="/blogview">Vad krävs när man hyr en bil?
</a>
      </h3>

      <div className="card-meta">

        <div className="publish-date">
        <AccessTimeIcon/>

          <time datetime="2022-01-14">January 14, 2022</time>
        </div>

        <div className="comments">
        <ChatBubbleOutlineIcon/>

          <data value="114">114</data>
        </div>

      </div>

    </div>

  </div>
</li>

<li>
  <div className="blog-card">

    <figure className="card-banner">

      <a href="/blogview">
        <img src={blogimg4} alt="New rules for handling our cars" loading="lazy"
          className="w-100"/>
      </a>

      <a href="/blogview" className="btn card-badge">Företag</a>

    </figure>

    <div className="card-content">

      <h3 className="h3 card-title">
        <a href="/blogview">Nya regler för hantering av våra bilar
</a>
      </h3>

      <div className="card-meta">

        <div className="publish-date">
        <AccessTimeIcon/>

          <time datetime="2022-01-14">January 14, 2022</time>
        </div>

        <div className="comments">
        <ChatBubbleOutlineIcon/>

          <data value="114">114</data>
        </div>

      </div>

    </div>

  </div>
</li>

</ul></div>
    </div>
  )
}

export default BlogCardCompo