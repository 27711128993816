import React from 'react'
import videos from '../../assests/touzabillar.mp4'

const VideoCompo = () => {
 
  return (
    <div >
           <div className='section about' id='about'>
          <div className="container" >

<div>
<figure className="about-banner video-container">
<video controls loop={true} autoPlay={true} muted={true} className='responsive-video'>
        <source src={videos} type="video/mp4" /> {/* Use the imported video file */}
        Your browser does not support the video tag.
      </video>
</figure>
</div>
<div className="about-content">
<div>
  <p className="section-title">
Njut av en titt på vår tidigare samling. Vi fortsätter att uppfylla löftet om kvalitet, lika mycket idag som då
</p>

</div>
</div>
</div>

    </div>
    </div>
  )
}

export default VideoCompo