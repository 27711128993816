import React,{useState} from 'react'
import NavBar from '../Components/NavBar/NavBar'
import Footer from '../Components/Footer/Footer'
import Carcard from '../Components/carcard/Carcard'
const Carscree = () => {
  const [selectedFilter, setSelectedFilter] = useState('');
  function handleFilterChange(event) {
    setSelectedFilter(event.target.value);
  }
  
  return (
    <div >
   <NavBar/>
   <div className='section featured-car' style={{marginTop:45}}>

   
   <div className="container">
   <div className="title-wrapper">
   <h2 className="h2 section-title">bilar i lager
</h2>

</div>
<div className='input-box'>
  <input type='text' placeholder='leta igenom bilen
' className='input'/>
  <div>
    <select value={selectedFilter} onChange={handleFilterChange} style={{width:200, height:50, fontSize:15}}>
      <option value="">All</option>
      <option value="option1">EV</option>
      <option value="option2">Deisel</option>
      <option value="option3">bensin</option>
    </select>
  </div>

  </div>
   <ul className="featured-car-list">
   <Carcard/>
   <Carcard/><Carcard/><Carcard/>
   <Carcard/><Carcard/><Carcard/><Carcard/><Carcard/><Carcard/><Carcard/><Carcard/>
   </ul>
   </div>
   </div>
   <Footer/>
    </div>
  )
}

export default Carscree