import React from 'react'
import logo from '../../assests/images/logo1.jpeg'
import logo1 from '../../assests/images/logo.jpeg'
import logo2 from '../../assests/images/logo3.jpeg'
import logo3 from '../../assests/images/logo4.jpeg'
import logo4 from "../../assests/images/logo5.jpeg"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import { windowWidth } from '../const/Const'
const InfoCard = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: windowWidth<731&&windowWidth>314 ?2 : windowWidth<314 ?1:3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, // Set the desired interval in milliseconds
    arrows: false, // Set the arrows option to false to hide the arrows
    margin:2,
  
  };
  return (
    <div className='section get-start'>
       <div className="container CBox">


<Slider {...settings} style={{height:250}} >

<img src={logo}  alt='not show'   className='logo'/>
<img src={logo1} alt='not show'   className='logo'/>
<img src={logo2} alt='not show'   className='logo'/>
<img src={logo3} alt='not show'   className='logo'/>
<img src={logo4} alt='not show'   className='logo'/>


</Slider>

</div>    
    </div>
  )
}

export default InfoCard